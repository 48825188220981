.comment {
  background: linear-gradient(
    180deg,
    #518bff -31.33%,
    rgba(8, 22, 50, 0.88) 71.59%,
    rgba(10, 9, 12, 0.28) 100.59%
  );
  box-shadow: 0px 1px 0px 0px rgba(122, 166, 255, 0.82) inset;
  .card {
    flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid rgba(222, 207, 250, 0.1);
    background: rgba(21, 20, 23, 0.5);
    backdrop-filter: blur(12px);
  }
  .cards {
    overflow: hidden;
    white-space: nowrap;
    &:hover {
      .card-slide {
        animation-play-state: paused;
      }
    }
    .card-slide {
      cursor: pointer;
      animation: 35s slide infinite linear;
      display: inline-block;
    }

    @keyframes slide {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(-100%);
      }
    }
  }
}
