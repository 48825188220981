.contact {
  .contact-you {
    .contact-input {
      border-radius: 8px;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      transition: border 0.3s ease-in-out; // Add transition for border property
      background-color: transparent !important;
      &:focus,
      &:not(:placeholder-shown),
      &:focus ~ .contact-input {
        border: 1px solid var(--Blue-300, #7aa6ff);
      }

      &:focus {
        border: 1px solid var(--Blue-500, #2970ff);
        outline: none;
      }
    }
    .input-form {
      width: 100%;
    }
  }
}
