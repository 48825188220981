.app {
  .span {
    background: linear-gradient(#cbdcff, #2970ff);
    -webkit-background-clip: text; /* For Safari/Chrome/iOS */
    background-clip: text;
    font-weight: bold;
    color: transparent;
  }
  .span1 {
    background: var(
      --Gradient,
      linear-gradient(180deg, #cbdcff 0%, #a2c1ff 0.01%, #184399 100%)
    );
    -webkit-background-clip: text; /* For Safari/Chrome/iOS */
    background-clip: text;
    font-weight: bold;
    color: transparent;
  }
  .blur-effect {
    filter: blur(8px);
  }

  @media (min-width: 1025px) {
    .blur-effect {
      filter: blur(0) !important;
    }
  }
  .msg-btn {
    position: relative;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    overflow: hidden;
    font-weight: 400;
    border-radius: 6px;
    letter-spacing: 1px;
    border: 1px solid #a2c1ff;
    background-image: linear-gradient(
      to left,
      #2970ff,
      #7aa6ff,
      #7aa6ff,
      #2970ff
    );
    background-size: 300%;
    background-position: right;
    box-shadow: 0px -60px 32px 0px rgba(171, 132, 244, 0.3) inset;
    transition: 300ms background-position ease-in-out, 300ms border ease-in-out;
    &:before {
      content: "";
      position: absolute;
      z-index: 100;
      width: 150px;
      height: 100%;
      background-image: linear-gradient(
        120deg,
        rgba(255, 255, 255, 0) 30%,
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0) 70%
      );
      top: 0;
      left: -150px;
      animation: animate 4s linear infinite;
    }
    &:hover:before {
      animation: pause;
    }
    &:focus:before {
      animation: pause;
    }
    &:hover {
      border: 1px solid #2059cc;
      background-position: left;
      box-shadow: 0px -60px 32px 0px rgba(171, 132, 244, 0.3) inset;
    }
    &:focus {
      border: 1px solid #a2c1ff;
      background: #2970ff !important;
      box-shadow: 0px -60px 32px 0px rgba(171, 132, 244, 0.3) inset;
    }

    @keyframes animate {
      0% {
        left: -150px;
      }
      20% {
        left: 100%;
      }
      100% {
        left: 100%;
      }
    }
  }
  .react-select__control, .react-select__single-value, .react-select__value-container, .react-select-container, .react-select__indicator, .react-select__indicator-separator{
    background-color: rgba(51, 51, 51, 0.20);
    border-color: #333741;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    color: #85888E;
    text-overflow: ellipsis;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .react-select__single-value {
    background-color: transparent;
  }

  .react-select__menu {
    background-color: var(--Gray-iron-900, #18181b);
    border: 1px solid #85888E;
  }
  @media (max-width: 640px) {
    .msg-btn {
      font-weight: 500;
    }
  }
  // .shimmer-btn {
  //   font-weight: 500;
  //   border-radius: 6px;
  //   --ofset: 0.5px;
  //   position: relative;
  //   max-width: 100%;
  //   overflow: hidden;
  //   ::before {
  //     content: "";
  //     background: conic-gradient(transparent 180deg, white, transparent);
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     transform: translate(-50%, -50%);
  //     aspect-ratio: 1;
  //     width: 100%;
  //     animation: rotate 2s linear infinite;
  //   }
  //   ::after {
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     content: "Xabar qoldirish";
  //     background: inherit;
  //     border-radius: inherit;
  //     position: absolute;
  //     inset: var(--ofset);
  //     height: calc(100% - 2 * var(--ofset));
  //     width: calc(100% - 2 * var(--ofset));
  //   }
  //   .msg-btn {
  //     position: absolute;
  //     inset: 0;
  //     z-index: 10;
  //     padding: 10px 20px;
  //     font-weight: 700;
  //     justify-content: center;
  //     align-items: center;
  //     gap: 10px;
  //     border-radius: 6px;
  //     border: 1px solid #a2c1ff;
  //     background-image: linear-gradient(
  //       to left,
  //       #2970ff,
  //       #7aa6ff,
  //       #7aa6ff,
  //       #2970ff
  //     );
  //     background-size: 300%;
  //     background-position: right;
  //     box-shadow: 0px -60px 32px 0px rgba(171, 132, 244, 0.3) inset;
  //     transition: 300ms background-position ease-in-out,
  //       300ms border ease-in-out;
  //     &:hover {
  //       border: 1px solid #2059cc;
  //       background-position: left;
  //       box-shadow: 0px -60px 32px 0px rgba(171, 132, 244, 0.3) inset;
  //     }
  //     &:focus {
  //       border: 1px solid #a2c1ff;
  //       background: #2970ff !important;
  //       box-shadow: 0px -60px 32px 0px rgba(171, 132, 244, 0.3) inset;
  //     }
  //   }
  //   @keyframes rotate {
  //     from {
  //       transform: translate(-50%, -50%) scale(1.2) rotate(0turn);
  //     }
  //     to {
  //       transform: translate(-50%, -50%) scale(1.2) rotate(1turn);
  //     }
  //   }
  // }
}
