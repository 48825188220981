.footer {
  .links {
    .active-link {
      color: rgb(37 99 235) !important;
    }
    li {
      transition: all 0.3s ease-in-out;
      &:hover {
        color: rgb(96 165 250);
      }
    }
  }
}
