.features {
  .feature-card-true {
    border-radius: 12px;
    border: 1px solid rgba(222, 207, 250, 0.1);
    background: rgba(21, 20, 23, 0.5);
    backdrop-filter: blur(12px);
  }
  .howwe-line {
    width: 2px;
    height: 170px;
    flex-shrink: 0;
    background: var(
      --Gradient,
      linear-gradient(180deg, #cbdcff 0%, #a2c1ff 0.01%, #184399 100%)
    );
  }
  .msg-btn2 {
    position: relative;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    font-weight: 400;
    font-size: 10px;
    overflow: hidden;
    border-radius: 4px 4px 9px 9px;
    background-color: #2e2e3b;
    background-size: 300%;
    background-position: right;
    transition: background-color 0.3 ease-in-out;
    &:before {
      content: "";
      position: absolute;
      z-index: 100;
      width: 150px;
      height: 100%;
      background-image: linear-gradient(
        120deg,
        rgba(255, 255, 255, 0) 30%,
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0) 70%
      );
      top: 0;
      left: -150px;
      animation: animate 2.5s linear infinite;
    }
    &:hover:before {
      animation: pause;
    }
    &:hover {
      background-color: #5b4f60;
    }

    @keyframes animate {
      0% {
        left: -150px;
      }
      20% {
        left: 100%;
      }
      100% {
        left: 100%;
      }
    }
  }
  .bankomat {
    .msg-btn1 {
      position: relative;
      justify-content: center;
      align-items: center;
      padding: 6px 12px;
      font-weight: 400;
      overflow: hidden;
      border-radius: 4px;
      background-color: #504555;
      background-size: 300%;
      background-position: right;
      transition: background-color 0.3 ease-in-out;
      &:before {
        content: "";
        position: absolute;
        z-index: 100;
        width: 150px;
        height: 100%;
        background-image: linear-gradient(
          120deg,
          rgba(255, 255, 255, 0) 30%,
          rgba(255, 255, 255, 0.8),
          rgba(255, 255, 255, 0) 70%
        );
        top: 0;
        left: -150px;
        animation: animate 2.5s linear infinite;
      }
      &:hover:before {
        animation: pause;
      }
      &:hover {
        background-color: #5b4f60;
      }

      @keyframes animate {
        0% {
          left: -150px;
        }
        20% {
          left: 100%;
        }
        100% {
          left: 100%;
        }
      }
    }
  }
  .msg-btn3 {
    position: relative;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    padding: 5px 10px;
    font-size: 11px;
    overflow: hidden;
    border-radius: 6px;
    background-image: linear-gradient(
      to left,
      #2970ff,
      #7aa6ff,
      #7aa6ff,
      #2970ff
    );
    background-size: 300%;
    background-position: right;
    box-shadow: 0px -60px 32px 0px rgba(171, 132, 244, 0.3) inset;
    transition: 300ms background-position ease-in-out, 300ms border ease-in-out;
    &:before {
      content: "";
      position: absolute;
      z-index: 100;
      width: 150px;
      height: 100%;
      background-image: linear-gradient(
        120deg,
        rgba(255, 255, 255, 0) 30%,
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0) 70%
      );
      top: 0;
      left: -150px;
      animation: animate 2.5s linear infinite;
    }
    &:hover:before {
      animation: pause;
    }
    &:focus:before {
      animation: pause;
    }
    &:hover {
      background-position: left;
      box-shadow: 0px -60px 32px 0px rgba(171, 132, 244, 0.3) inset;
    }
    &:focus {
      border: 1px solid #a2c1ff;
      background: #2970ff !important;
      box-shadow: 0px -60px 32px 0px rgba(171, 132, 244, 0.3) inset;
    }

    @keyframes animate {
      0% {
        left: -150px;
      }
      20% {
        left: 100%;
      }
      100% {
        left: 100%;
      }
    }
  }
}
