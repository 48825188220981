  .step-content {
    border-radius: 24px;
    border: 2px solid rgba(66, 66, 66, 0.35);
    background: var(--Gray-iron-900, #18181b);
  }
  @media only screen and (max-width: 640px) {
    .step-content  {
      border-radius: 24px;
      border: none;
      background: transparent;
    }
  }
 
  .btn-prev {
    border-radius: 8px;
    border: 1px solid var(--Blue, #2970ff);

    /* Shadows/shadow-xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  .contact-input {
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-color: #333741;
    transition: border 0.3s ease-in-out; // Add transition for border property
    &:focus,
    &:not(:placeholder-shown),
    &:focus ~ .contact-input {
      border: 1px solid var(--Blue-300, #7aa6ff);
    }

    &:focus {
      border: 1px solid var(--Blue-500, #2970ff);
      outline: none;
    }
  }