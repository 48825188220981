.home {
  .msg-btn1 {
    position: relative;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    padding: 5px 10px;
    overflow: hidden;
    border-radius: 6px;
    background-image: linear-gradient(
      to left,
      #2970ff,
      #7aa6ff,
      #7aa6ff,
      #2970ff
    );
    background-size: 300%;
    background-position: right;
    box-shadow: 0px -60px 32px 0px rgba(171, 132, 244, 0.3) inset;
    transition: 300ms background-position ease-in-out, 300ms border ease-in-out;
    &:before {
      content: "";
      position: absolute;
      z-index: 100;
      width: 150px;
      height: 100%;
      background-image: linear-gradient(
        120deg,
        rgba(255, 255, 255, 0) 30%,
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0) 70%
      );
      top: 0;
      left: -150px;
      animation: animate 4s linear infinite;
    }
    &:hover:before {
      animation: pause;
    }
    &:focus:before {
      animation: pause;
    }
    &:hover {
      background-position: left;
      box-shadow: 0px -60px 32px 0px rgba(171, 132, 244, 0.3) inset;
    }
    &:focus {
      border: 1px solid #a2c1ff;
      background: #2970ff !important;
      box-shadow: 0px -60px 32px 0px rgba(171, 132, 244, 0.3) inset;
    }

    @keyframes animate {
      0% {
        left: -150px;
      }
      20% {
        left: 100%;
      }
      100% {
        left: 100%;
      }
    }
  }
}
