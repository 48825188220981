.pagination {
  .next {
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #d0d5dd);
    background: var(--Base-White, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    transition: all 0.3s ease-in-out;
  }
  .next:hover {
    background: #f9fafb;
  }
  .next:focus {
    background: #f9fafb;
  }
}
